<template>
    <v-row no-gutters>
        <v-col cols="12">
            <AccountBar :organizationId="$route.params.organizationId" :account="account" class="mb-6" v-if="account"></AccountBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link>
                    <template v-if="organization">
                    &gt; <router-link :to="{ name: 'organization-dashboard', params: { realm: this.$route.params.organizationId } }">{{ organization.name }}</router-link>
                    </template>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card class="pa-0" v-if="Array.isArray(orderList)">
                        <v-app-bar color="green darken-2" dark flat dense>
                            <v-app-bar-title>Orders ({{ orderList.length }})</v-app-bar-title>
                            <v-spacer/>
                            <!-- TODO: enable invite new users only for organizations with business or enterprise add-ons; organizations on the essential plan have only one admin -->
                            <!-- <v-btn icon :to="{ name: 'organization-invite-user', params: { organizationId: this.$route.params.organizationId } }">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width></font-awesome-icon>
                            </v-btn> -->
                        </v-app-bar>
                        <v-card-text>
                            <p class="mb-0 pb-0" v-if="orderList.length === 0">
                                No orders found
                            </p>
                            <v-simple-table v-if="orderList.length > 0">
                                <template #default>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                            <th>ID</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="order in orderList" :key="order.id">
                                            <td>
                                                {{ formatDate(order.created_on) }}
                                            </td>
                                            <td>
                                                {{ formatAmount(order.currency, order.amount_csu) }}
                                            </td>
                                            <td>
                                                {{ orderStatusDisplay(order.status) }}
                                            </td>
                                            <td>
                                                <router-link :to="viewOrderLink(order)">{{ orderIdText(order.id) }}</router-link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import { fromCurrencyAmountCSU } from '@libertyio/currency-util-js';
import AccountBar from '@/components/AccountBar.vue';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';

function formatId(input) {
    const parts = [];
    let i = 0;
    while (i < input.length) {
        const part = input.substr(i, 5);
        parts.push(part);
        i += 5;
    }
    return parts.join('-');
}

export default {
    components: {
        AccountBar,
        AccessDeniedOverlay,
    },
    data: () => ({
        orderList: null,
        error: null,
        forbiddenError: false,
        account: null, // only if query specifies an account; otherwise we search all accounts
    }),
    computed: {
        ...mapState({
            organization: (state) => state.organization,
        }),
    },
    watch: {
        $route(newValue, oldValue) {
            this.loadOrderList();
            if (newValue.query.account_id !== oldValue.query.account_id) {
                this.loadAccount();
            }
        },
    },
    methods: {
        async loadOrderList() {
            try {
                this.$store.commit('loading', { loadOrderList: true });
                const query = {
                    account_id: null,
                };
                if (this.$route.query.account_id) {
                    query.account_id = this.$route.query.account_id;
                }
                if (this.$route.query.status) {
                    query.status = this.$route.query.status;
                }
                const response = await this.$client.organization(this.$route.params.organizationId).accountOrder.search(query);
                if (response?.list) {
                    this.orderList = response.list;
                } else {
                    this.orderList = null;
                }
            } catch (err) {
                console.error('loadOrderList failed', err);
            } finally {
                this.$store.commit('loading', { loadOrderList: false });
            }
        },
        viewOrderLink(item) {
            return { name: 'organization-view-order', params: { organizationId: this.$route.params.organizationId, orderId: item.id } };
        },
        formatDate(timestamp) {
            return new Date(timestamp).toLocaleDateString();
        },
        formatAmount(currency, amountCSU) {
            return fromCurrencyAmountCSU(currency, amountCSU).toStringWithCurrencySymbol();
        },
        orderStatusDisplay(status) {
            if (status === 'paid') {
                return 'Paid, waiting to be processed...';
            }
            if (status === 'activated') {
                return 'Processing...';
            }
            // TODO: is there a 'processed' ?
            if (status === 'completed') {
                return 'Fulfilled';
            }
            if (status === 'cancelled') {
                return 'Cancelled';
            }
            return status;
        },
        orderIdText(orderId) {
            return formatId(orderId);
        },
        async loadAccount() {
            try {
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.organization(this.$route.params.organizationId).account.get({ id: this.$route.query.account_id });
                if (response) {
                    this.account = response;
                } else {
                    this.account = null;
                }
            } catch (err) {
                console.error('loadAccount failed', err);
                if (err.response?.status === 403) {
                    this.forbiddenError = true;
                }
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
    },
    mounted() {
        this.loadOrderList();
        if (this.$route.query.account_id) {
            this.loadAccount();
        }
    },
};
</script>
